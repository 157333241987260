import React, { Component } from 'react';
import './App.css';

import GpCart from "@gp-cart/cart-sfc-qa-release-withtestharness";

class App extends Component {
  render() {
    return (
      <div>
        <GpCart/>
      </div>
    );
  }
}

export default App;
